<template>
  <el-dialog
    top="80px"
    width="600px"
    class="create-user-popup"
    title="Tạo tổ chức mới"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Tên tổ chức" prop="summary">
        <el-input v-model="form.summary" :disabled="callingAPI" placeholder="Nhập tên tổ chức"></el-input>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input
          v-model="form.description"
          :disabled="callingAPI"
          placeholder="Nhập mô tả tổ chức"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleCreateOrganization">Tạo mới</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { createOrganization } from '@/services/organizations'
export default {
  name: 'CreateOrganization',
  props: {
    visible: Boolean
  },
  data() {
    return {
      callingAPI: false,
      form: {
        summary: '',
        description: '',
        menus: []
      },
      rules: {
        summary: [
          { required: true, message: 'Tên tổ chức không được để trống', trigger: ['blur', 'change'] }
        ],
        description: [
          { required: true, message: 'Mô tả không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    handleCreateOrganization() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          const data = {
            ...this.form,
            menus: '',
            user_ids: [],
            place_ids: []
          }
          createOrganization(data).then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Tạo mới tổ chức thành công'
            })
            this.$refs.form.resetFields()
            this.$emit('reload')
            this.callingAPI = false
          })
            .catch(() => {
              this.$notify.error({
                title: 'Thất bại',
                message: 'Tạo mới thất bại'
              })
              this.callingAPI = false
            })
        }
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
